import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = []

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Designer Samples",
  path: "react-designer-samples",
}

const ReactDesignerSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactDesignerSamples
